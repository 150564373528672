import React, { FunctionComponent, useMemo } from 'react';
import Button from '3-components/Buttons/Button';
import Icon from '3-components/Icon/Icon';

interface StoryModalBlockProps {
    title: string,
    story: string,
    cover: string,
    coverAltText?: string,
    storyUrl?: string,
    ctaTitle?: string
}

const StoryModalBlock: FunctionComponent<StoryModalBlockProps> = props => {
    const {
        title,
        story,
        cover,
        coverAltText,
        storyUrl,
        ctaTitle
    } = props;
    const optimisedImgSrc = useMemo(() => {
        const safeStoryUrl = cover || '';

        if (!safeStoryUrl) {
            return {
                jpg: '',
                webp: ''
            };
        }

        const urlWithoutExtension = safeStoryUrl.replace(/\.\w*$/, '');

        return {
            jpg: `${urlWithoutExtension}.jpg`,
            webp: `${urlWithoutExtension}.webp`
        };
    }, [cover]);

    return (
        <div className="story-modal-block">
            <div className="story-modal-image-container">
                <picture className="story-modal-image">
                    <source srcSet={optimisedImgSrc.webp} type="image/webp" />
                    <img className="story-modal-image" src={optimisedImgSrc.jpg} alt={`${coverAltText}`} />
                </picture>
            </div>
            <div className="story-modal-intro">
                <h2>{title}</h2>
                <p>
                    {story}
                    {storyUrl && (
                        <a className="story-modal-link" href={storyUrl}>
                            Continue reading <Icon icon="arrow-right" />
                        </a>
                    )}
                </p>
                {ctaTitle && (
                    <div className="modal-donate-cta">
                        <h3>{ctaTitle}</h3>
                        <Button
                            href="https://donate.charidy.com/24679"
                            btnText="Donate to add a star"
                            btnCustomClass="story-cta-button"
                            btnSize="sm"
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default StoryModalBlock;
