import { formatHugeAmountToAUCurrency, formatToAUCurrency, formatToAUInt } from '../../3-components/Utils/NumberUtils';

export default function updateDonationInfo(totalDonationAmount: number, totalItems: number) {
    const donationTargetTitle = document.querySelector('.donation-target-title');
    if (donationTargetTitle) {
        let totalToDisplay = formatToAUCurrency(totalDonationAmount);
        if (totalDonationAmount / 1000000000 >= 1) {
            totalToDisplay = formatHugeAmountToAUCurrency(totalDonationAmount);
        }

        donationTargetTitle.textContent = totalToDisplay;
    }
    const donationTargetSubtitle = document.querySelector('.donation-target-subtitle');
    if (donationTargetSubtitle) {
        const donorsCount = formatToAUInt(totalItems);
        donationTargetSubtitle.textContent = `Raised by ${donorsCount} donors`;
    }
}
