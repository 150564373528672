// get a reference to our predefined button
const scrollToTopBtn = document.querySelector('.back-to-top');

function handleScroll() {
    const scrollableHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;

    const GOLDEN_RATIO = 0.5;

    if ((document.documentElement.scrollTop / scrollableHeight) > GOLDEN_RATIO) {
        // show button
        scrollToTopBtn?.classList.add('is-visible');
        // scrollToTopBtn.classList.add = 'is-visible';
    } else {
        // hide button
        scrollToTopBtn?.classList.remove('is-visible');
    }
}

function scrollToTop() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
    });
}

document.addEventListener('DOMContentLoaded', () => {
    if (scrollToTopBtn !== null) {
        scrollToTopBtn.addEventListener('click', scrollToTop);
        document.addEventListener('scroll', handleScroll);
    }
});
