const copyPageUrl = () => {
    const pageUrl = document.location.href;
    const copyBtns = document.querySelectorAll<HTMLElement>('.copy-page-url-btn');

    if (copyBtns) {
        copyBtns.forEach(copyBtn => {
            copyBtn.addEventListener('click', async () => {
                try {
                    await navigator.clipboard.writeText(pageUrl);
                    const copyLinkText = copyBtn.nextElementSibling;
                    copyLinkText?.classList.add('visible');
                    setTimeout(() => {
                        copyLinkText?.classList.remove('visible');
                    }, 2000);
                } catch (e) {
                    // Do nothing
                    console.error(e);
                }
            });
        });
    }
};

document.addEventListener('DOMContentLoaded', copyPageUrl);
