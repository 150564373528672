import React from 'react';
import { connectStateResults, ClearRefinements } from 'react-instantsearch-dom';
import { SearchState } from 'react-instantsearch-core';

const NoResults = ({ searchResults }: SearchState) => {
    if (!searchResults || searchResults.nbHits > 0) {
        return null;
    }

    const hasRefinements = searchResults.getRefinements().length > 0;
    const description = hasRefinements
        ? 'Try to reset your applied filters.'
        : 'Please try another search query.';

    return (
        <div className="hits-empty-state">
            <p className="hits-empty-state-title">No results found</p>
            <p className="hits-empty-state-description">{description}</p>

            <ClearRefinements
                clearsQuery={true}
                translations={{ reset: 'Reset filters' }}
            />
        </div>
    );
};

export default connectStateResults(NoResults);
