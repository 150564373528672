export const createFacebookShareUrl = (urlToShare: string) => `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`;

export const createTwitterShareUrl = (urlToShare: string) => {
    const bodyText = 'I’ve just added a star to light up the lives of children who are blind or have low vision this Christmas. Join me with a donation to Vision Australia’s Carols by Candlelight presented by AAMI.';
    const escapedBodyText = encodeURIComponent(bodyText);
    return `https://twitter.com/intent/tweet?text=${escapedBodyText}&url=${urlToShare}`;
};

export const createEmailShareUrl = (urlToShare: string) => {
    const titleText = 'I’m lighting up lives - view my star';
    const escapedTitleText = encodeURIComponent(titleText);
    const bodyText = `I’ve just added a star to light up the lives of children who are blind or have low vision this Christmas.
View my star ${urlToShare}
To add your star, join me with a donation to Vision Australia’s Carols by Candlelight presented by AAMI.
Donate to add a star http://www.givetocarols.com/`;
    const escapedBodyText = encodeURIComponent(bodyText);
    return `mailto:?subject=${escapedTitleText}`
            + `&body=${escapedBodyText}`;
    // return `https://www.facebook.com/sharer/sharer.php?u=${urlToShare}`;
};

export default {
    createFacebookShareUrl,
    createTwitterShareUrl,
    createEmailShareUrl
};
