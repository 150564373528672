import React, { FunctionComponent } from 'react';

interface LuminaryCreditProps {
    additionaClasses?: string
}

const LuminaryCredit: FunctionComponent<LuminaryCreditProps> = props => {
    const { additionaClasses } = props;
    return (
        <div className={`luminary-credit ${additionaClasses}`}>
            <p>Site by</p>
            <a href="https://www.luminary.com/" target="_blank" rel="noopener norefferer noreferrer">
                <img src="/assets/illustrations/Luminary-Text.svg" alt="Luminary" />
            </a>
        </div>
    );
};

export default LuminaryCredit;
