import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import qs from 'qs';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, SortBy, ClearRefinements, Configure, CurrentRefinements, RefinementList, Panel } from 'react-instantsearch-dom';
import AlgoliaPagination from '3-components/AlgoliaPagination/AlgoliaPagination';
import AlgoliaSearchInput from '3-components/AlgoliaSearchInput/AlgoliaSearchInput';
import CustomStats from './widgets/CustomStats';
import CustomHit from './widgets/CustomHit';
import NoResults from './widgets/NoResults';

const DEBOUNCE_TIME = 400;

// NOTE: This is the demo Algolia searchClient. Update to your projects ID
const listingClient = algoliasearch(
    'latency',
    '6be0576ff61c053d5f9a3225e2a90f76'
);

const createURL = (state: qs.ParsedQs) => `?${qs.stringify(state)}`;

const searchStateToUrl = (searchState: qs.ParsedQs) => (searchState ? `${window.location.pathname}${createURL(searchState)}` : '');

const urlToSearchState = ({ search }: Location) => qs.parse(search.slice(1));

const FilteredListingApp = () => {
    const [searchState, setSearchState] = useState(urlToSearchState(window.location));
    const [debouncedSetState, setDebouncedSetState] = useState(0);

    const onSearchStateChange = (updatedSearchState: qs.ParsedQs) => {
        clearTimeout(debouncedSetState);

        setDebouncedSetState(
            window.setTimeout(() => {
                window.history.pushState(
                    updatedSearchState,
                    '',
                    searchStateToUrl(updatedSearchState)
                );
            }, DEBOUNCE_TIME)
        );

        setSearchState(updatedSearchState);
    };

    return (
        <InstantSearch
            indexName="bestbuy"
            searchClient={listingClient}
            searchState={searchState}
            onSearchStateChange={onSearchStateChange}
            createURL={createURL}
        >
            <Configure
                hitsPerPage={9}
            />

            <div className="filtered-listing-searchbar">
                <AlgoliaSearchInput />
            </div>

            <div className="filtered-listing-active-refinements">
                <CurrentRefinements />

                <ClearRefinements
                    translations={{ reset: 'Clear all filters' }}
                />
            </div>

            <div className="filtered-listing-toolbar">
                <CustomStats />

                <SortBy
                    className="container-option"
                    defaultRefinement="instant_search"
                    items={[
                        {
                            label: 'Sort by featured',
                            value: 'instant_search'
                        },
                        {
                            label: 'Price ascending',
                            value: 'instant_search_price_asc'
                        },
                        {
                            label: 'Price descending',
                            value: 'instant_search_price_desc'
                        }
                    ]}
                />
            </div>

            <div className="filtered-listing-filters-and-results-wrapper">
                <div className="filtered-listing-filters">
                    <Panel header="Brands">
                        <RefinementList
                            attribute="brand"
                        />
                    </Panel>
                </div>

                <div className="filtered-listing-results">
                    <Hits hitComponent={CustomHit} />

                    <NoResults />

                    <AlgoliaPagination />
                </div>
            </div>

        </InstantSearch>
    );
};

const el = document.getElementById('filtered-listing-app');

if (el) {
    ReactDOM.render(
        <FilteredListingApp />,
        el
    );
}
