import Bowser from 'bowser';
import MicroModal from 'utils/LocalMicromodal';
import { createCookie, readCookie } from '3-components/Utils/CookieUtils';

const SupportedBrowsers = {
    desktop: {
        chrome: '>=87',
        firefox: '>=85',
        edge: '>=87',
        safari: '>=14.1'
    },
    tablet: {
        chrome: '>=94',
        firefox: '>=91',
        safari: '>=14'
    },
    mobile: {
        chrome: '>=94',
        firefox: '>=91',
        safari: '>=14'
    }
};

const DetectBrowserCookieKey = 'oldbrowserwarning';
const DetectBrowserCookieValue = 'true';

const AlertOldBrowserUser = () => {
    const pageWithModal = document.querySelector('#browser-support-modal');
    // only check if page contains animation container
    if (pageWithModal) {
        const cookieValue = readCookie(DetectBrowserCookieKey);
        const currentBrowser = Bowser.getParser(window.navigator.userAgent);
        const isSupported = currentBrowser.satisfies(SupportedBrowsers);
        if (!isSupported) {
            if (cookieValue !== DetectBrowserCookieValue) {
                MicroModal.show('browser-support-modal', { disableScroll: true, disableFocus: true });
            }
            // create cookie, indicates that we've show browser support modal, last for 3 days
            createCookie(DetectBrowserCookieKey, DetectBrowserCookieValue, 3);
        }
    }
};

document.addEventListener('DOMContentLoaded', AlertOldBrowserUser);
