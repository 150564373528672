import React, { FunctionComponent, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { RenderWithPropsFn } from 'utils/renderListWithDataProps';
import Button from '3-components/Buttons/Button';
import LoadingIndicator from '3-components/LoadingIndicator/LoadingIndicator';
import { getStar } from '3-components/Utils/Services';
import { createFacebookShareUrl, createTwitterShareUrl, createEmailShareUrl } from '3-components/Utils/ShareUtils';

const ThankYouPrompt: FunctionComponent = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [myStar, setMyStar] = useState();
    const [attempt, setAttempt] = useState(1);
    const urlParams = new URLSearchParams(window.location.search);
    const [starId] = useState(urlParams.get('star'));

    const attemptDelay = 2.5; // in seconds
    const maxWaitTime = 64; // in seconds
    const messageChangeTime = 5; // in seconds
    const maxAttempt = maxWaitTime / attemptDelay;

    const waitMessages = [
        'Please wait while we add your star to our candle...',
        'Adding extra sparkle...',
        'Glowing brighter...',
        'Lighting up lives...',
        'Enhancing your star’s shimmer...',
        'Brightening your glow...'
    ];

    const getCalculatedMessageIndex = () => (Math.ceil(attempt / (messageChangeTime / attemptDelay)) - 1) % waitMessages.length;

    useEffect(() => {
        const failHandler = () => {
            if (attempt < maxAttempt) {
                setTimeout(() => {
                    setAttempt(attempt + 1);
                }, attemptDelay * 1000);
            } else {
                setIsLoading(false);
            }
        };

        const getData = async function () {
            try {
                setIsLoading(true);
                const data = await getStar(`${starId}`);
                if (data) {
                    if (data.name && data.total) {
                        setMyStar(data);
                    }
                    setIsLoading(false);
                } else {
                    failHandler();
                }
            } catch (error) {
                console.error(error);
                failHandler();
            }
        };

        setTimeout(() => {
            getData();
        }, 500);
    }, [attempt, starId, maxAttempt]);

    const mystarUrl = `https://${window.location.host}/view?star=${starId}`;
    const facebookShareUrl = createFacebookShareUrl(mystarUrl);
    const twitterShareUrl = createTwitterShareUrl(mystarUrl);
    const emailShareUrl = createEmailShareUrl(mystarUrl);

    const [copyUrlText, setCopyUrlText] = useState('Copy share URL');
    const copyUrlHandle = () => {
        navigator.clipboard.writeText(mystarUrl);
        setCopyUrlText('URL copied !');
        setTimeout(() => {
            setCopyUrlText('Copy share URL');
        }, 1000);
    };

    const handleReload = () => {
        window.location.reload();
    };

    const renderLoading = () => (
        <div className="thankyou-waiting">
            <LoadingIndicator />
            <div className="waiting-message-container">
                {waitMessages.map((msg, index) => (
                    <p className={`waiting-message ${getCalculatedMessageIndex() === index ? 'fade-in' : 'fade-out'}`}>{msg}</p>
                ))}
            </div>
        </div>
    );

    const renderThankYouBlock = () => (
        <div className="thankyou-content">
            <img className="thankyou-star" src="/assets/images/Star-White.png" alt="white star" />
            <h1 className="thankyou-title">Thank you for lighting up lives for kids who are blind or have low vision this Christmas!</h1>
            <p className="thankyou-subtitle">Your star has been added to our candle to glow brighter.</p>
            <div className="donation-share-box">
                <p className="share-intro">Spread the word! We’d love you to share your generous donation with your friends.</p>
                <div className="line-separator" />
                <div className="donation-share-button-container">
                    <Button
                        href={facebookShareUrl}
                        target="_blank"
                        rel="noopener norefferer"
                        btnText="Share on Facebook"
                        btnSkin="3"
                        prefixIcon="facebook"
                        btnCustomClass="donation-share-button"
                    />
                    <Button
                        href={twitterShareUrl}
                        target="_blank"
                        rel="noopener norefferer"
                        btnText="Share on Twitter"
                        btnSkin="3"
                        prefixIcon="twitter-x"
                        btnCustomClass="donation-share-button"
                    />
                    <Button
                        href={emailShareUrl}
                        target="_blank"
                        rel="noopener norefferer"
                        btnText="Share via Email"
                        btnSkin="3"
                        prefixIcon="mail"
                        btnCustomClass="donation-share-button"
                    />
                    <Button
                        btnText={copyUrlText}
                        btnSkin="3"
                        prefixIcon="link"
                        btnCustomClass="donation-share-button"
                        onclick={copyUrlHandle}
                    />
                </div>
            </div>
            <Button
                href={`/view?star=${starId}&viewer=self`}
                btnText="View my star"
                suffixIcon="arrow-right"
                btnCustomClass="thankyou-main-button"
            />
        </div>
    );

    const renderComebackLater = () => (
        <div className="thankyou-content">
            <img className="thankyou-flame" src="/assets/images/flame-loader.gif" alt="Flame" />
            <h1 className="text-3xl">We’re still processing your donation.</h1>
            <p className="thankyou-subtitle">Please give us a moment and try again.</p>
            <Button
                btnText="Reload"
                btnCustomClass="thankyou-main-button"
                onclick={handleReload}
            />
        </div>
    );

    return (
        <div className="thankyou-page">
            {isLoading && renderLoading() }
            {!isLoading && myStar && renderThankYouBlock() }
            {!isLoading && !myStar && attempt > 2 && renderComebackLater() }
        </div>
    );
};

const ThankYouPromptAppInit: RenderWithPropsFn = ({ el }) => {
    ReactDOM.render(
        <ThankYouPrompt />, el
    );
};

export { ThankYouPromptAppInit as default };
