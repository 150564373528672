import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom';
import LoadingIndicator from '../../3-components/LoadingIndicator/LoadingIndicator';

const Animation = lazy(() => import('../../3-components/Animation/Animation'));

const Configuration = lazy(() => {
    const params = new URLSearchParams(window.location.search);
    const isDev = params.has('dev');

    if (isDev) {
        return import('../../3-components/AnimationConfiguration/AnimationConfiguration');
    }

    return import('../../3-components/AnimationDefaultConfiguration/AnimationDefaultConfiguration');
});

const Loader = () => (
    <Suspense fallback={<LoadingIndicator />}>
        <Configuration>
            {configuration => <Animation {...configuration} />}
        </Configuration>
    </Suspense>
);

let rendered = false;
const rootEl = document.querySelector('.js-animation-root');
const animationContainerEls = document.querySelectorAll('.js-animation-set-class-if-active');
const animationLocateButtonWrapper = document.querySelector('.js-animation-locate-button');
const isRenderedAfterInitialPageLoad = renderAnimationWrapper();

if (!isRenderedAfterInitialPageLoad && rootEl) {
    const observer = new MutationObserver(renderAnimationWrapper);
    observer.observe(rootEl, { attributeFilter: ['class'] });
}

function renderAnimationWrapper() {
    if (!rootEl) return false;

    const animationVisible = !rootEl.classList.contains('hidden');

    animationContainerEls.forEach(el => {
        el.classList.toggle('is-animation-active', animationVisible);
    });

    if (animationLocateButtonWrapper) {
        animationLocateButtonWrapper.classList.toggle('is-visible', animationVisible);
    }

    if (rendered) return rendered;

    if (animationVisible) {
        rendered = true;
        ReactDOM.render(<Loader />, rootEl);
    }

    return rendered;
}
