import React from 'react';
import Icon from '3-components/Icon/Icon';
import Image from '3-components/Image/Image';
import { SearchState } from 'react-instantsearch-core';

const CustomHit = ({ hit }: SearchState) => (
    // Example Custom Hit. This will usually be a Card component.
    <div className="eg-custom-hit-card">
        <Image
            imgSrc={hit.image}
            imgAlt={hit.name}
            imgWidth="100%"
            imgHeight="175px"
            objectFit="contain"
            imgCustomClass="hit-thumbnail"
        />
        <p className="hit-category">{hit.hierarchicalCategories.lvl0}</p>
        <h4 className="hit-title">{hit.name}</h4>
        <p className="hit-description">{hit.description}</p>
        <div className="price-rating-wrapper">
            <p className="hit-price">{new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(hit.price)}</p>
            <div className="hit-rating">
                <Icon icon="star" />
                <p>{hit.rating}</p>
            </div>
        </div>
    </div>
);
export default CustomHit;
