import React from 'react';
import { Pagination } from 'react-instantsearch-dom';
import Icon from '../Icon/Icon';

const AlgoliaPagination = () => (
    <Pagination
        showLast={true}
        translations={{
            previous: <Icon icon="chevron-left" />,
            next: <Icon icon="chevron-right" />,
            first: <Icon icon="chevron-double-left" />,
            last: <Icon icon="chevron-double-right" />
        }}
    />
);

export default AlgoliaPagination;
