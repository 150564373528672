const addScrollHandleForStickyToggle = stickyRoot => {
    document.addEventListener('scroll', () => {
        if (window.innerWidth > 1023) {
            const stickyRootStandsOut = stickyRoot.classList.contains('stand-out');
            const scrollPosY = window.scrollY;
            const posYLimit = 50;
            if (scrollPosY >= posYLimit && !stickyRootStandsOut) {
                stickyRoot.classList.add('stand-out');
            } else if (scrollPosY < posYLimit && stickyRootStandsOut) {
                stickyRoot.classList.remove('stand-out');
            }
        }
    });
};

document.addEventListener('DOMContentLoaded', () => {
    const displayToggleButtons = document.querySelectorAll('.display-toggle-button');

    if (displayToggleButtons) {
        displayToggleButtons.forEach(button => {
            if (button.classList.contains('active')) {
                button.setAttribute('aria-pressed', 'true');
            } else {
                button.setAttribute('aria-pressed', 'false');
            }

            button.addEventListener('click', () => {
                if (!button.classList.contains('active')) {
                    const activeDisplayToggles = document.querySelectorAll('.display-toggle-button.active');
                    if (activeDisplayToggles) {
                        activeDisplayToggles.forEach(activeButton => {
                            activeButton.classList.remove('active');
                            activeButton.setAttribute('aria-pressed', 'false');
                        });
                    }
                    button.classList.add('active');
                    button.setAttribute('aria-pressed', 'true');

                    const buttonText = button.innerText.toLowerCase();
                    const candleDisplay = document.querySelector('.candle-display');
                    const listDisplay = document.querySelector('.list-display');
                    const candleCredit = document.querySelector('.candle-luminary-credit');
                    const animationPauseBtnWrapper = document.querySelector('.js-animation-pause-button');
                    animationPauseBtnWrapper.classList.toggle('is-active', /candle/.test(buttonText));
                    const candleBlock = document.querySelector('.candle-block');
                    candleBlock.classList.toggle('is-animation-active', /candle/.test(buttonText));
                    const instructionLinkDesktop = document.querySelector('.instruction-container');
                    const locateButton = document.querySelector('.locate-button');
                    locateButton.classList.toggle('displaying-list', /list/.test(buttonText));
                    if (/candle/.test(buttonText)) {
                        candleDisplay.classList.remove('hidden');
                        listDisplay.classList.add('hidden');
                        candleCredit.classList.remove('hidden');
                        if (instructionLinkDesktop) {
                            instructionLinkDesktop.classList.remove('hidden');
                        }
                    } else if (/list/.test(buttonText)) {
                        candleDisplay.classList.add('hidden');
                        listDisplay.classList.remove('hidden');
                        candleCredit.classList.add('hidden');
                        if (instructionLinkDesktop) {
                            instructionLinkDesktop.classList.add('hidden');
                        }
                    }
                }
            });
        });
    }

    const stickyToggleRoot = document.querySelector('.homepage-top-sticky');
    if (stickyToggleRoot) {
        addScrollHandleForStickyToggle(stickyToggleRoot);
    }
});
