const tableWrapperInit = () => {
    // element that will be wrapped
    const tables = document.querySelectorAll('table');

    Array.prototype.forEach.call(tables, table => {
        if (table.parentElement.classList.contains('table-wrapper')) {
            return;
        }

        // create wrapper container
        const tableWrapper = document.createElement('div');
        tableWrapper.setAttribute('class', 'table-wrapper');

        table.parentNode.insertBefore(tableWrapper, table);
        tableWrapper.appendChild(table);
    });
};

tableWrapperInit();
