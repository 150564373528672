document.addEventListener('DOMContentLoaded', () => {
    const navButtons = document.querySelectorAll('.nav-menu > a');

    if (navButtons) {
        navButtons.forEach(button => {
            button.addEventListener('click', event => {
                event.preventDefault();
                if (!button.parentNode.classList.contains('active')) {
                    const buttonText = button.innerText;
                    const navItems = document.querySelectorAll('.nav-menu');

                    const currentFilterEls = document.querySelectorAll('.nav-menu .sr-only');
                    if (currentFilterEls) {
                        currentFilterEls.forEach(el => el.parentNode.removeChild(el));
                    }

                    if (navItems) {
                        navItems.forEach(item => {
                            item.classList.remove('active');

                            const itemText = item.querySelector('a').innerText;

                            if (itemText === buttonText) {
                                item.classList.add('active');
                                const currentFilterSpan = document.createElement('span');
                                currentFilterSpan.classList.add('sr-only');
                                currentFilterSpan.innerHTML = 'current filter';
                                item.firstChild.append(currentFilterSpan);
                                if (window.updateDonationFilter) {
                                    window.updateDonationFilter(itemText.toLowerCase());
                                }
                            }
                        });
                    }
                }
            });
        });
    }
});
