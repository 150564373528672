import React from 'react';
import { SearchBox } from 'react-instantsearch-dom';
import Icon from '../../3-components/Icon/Icon';

const AlgoliaSearchInput = () => (
    <SearchBox
        submit={<Icon icon="search" />}
        reset={<Icon icon="remove" />}
    />
);

export default AlgoliaSearchInput;
