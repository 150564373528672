import React from 'react';
import { connectStats } from 'react-instantsearch-dom';
import { SearchState } from 'react-instantsearch-core';

const Stats = ({ nbHits }: SearchState) => (
    <p>Showing {nbHits.toLocaleString()} results</p>
);

const CustomStats = connectStats(Stats);

export default CustomStats;
