import React from 'react';

interface ImageProps {
    imgSrc: string,
    imgAlt: string,
    imgWidth?: string,
    imgHeight?: string,
    aspectRatio?: string,
    alignment?: string,
    caption?: string,
    objectFit?: string,
    disableLazyLoading?: boolean,
    imgCustomClass?: string
}

const Image = ({
    imgSrc,
    imgAlt,
    caption,
    aspectRatio,
    alignment,
    imgWidth,
    imgHeight,
    objectFit,
    disableLazyLoading,
    imgCustomClass
}: ImageProps) => {
    const isSrcString = typeof imgSrc === 'string' || imgSrc === null;

    if (!isSrcString) {
        // probs a gatsby image or next js image
        return (
            <p>Gatsby or NextJS image?</p>
        );
    }

    return (
        <div
            className={`image-wrapper ${imgCustomClass}`}
            style={{
                '--image-width': imgWidth,
                '--image-height': imgHeight,
                '--image-alignment': alignment ? alignment : 'center center',
                '--image-object-fit': objectFit ? objectFit : 'cover',
                '--aspect-ratio': aspectRatio !== undefined ? aspectRatio : ''
            } as React.CSSProperties}
        >
            <figure>
                <picture className={aspectRatio !== undefined ? 'has-ratio' : ''}>
                    <img
                        src={imgSrc}
                        // srcSet={`${src}.webp`}
                        alt={imgAlt}
                        width={imgWidth}
                        height={imgHeight}
                        // class="{{#if imgCustomClass}} {{imgCustomClass}}{{/if}}"
                        // @ts-ignore
                        loading={disableLazyLoading ? '' : 'lazy'}
                        decoding="async"
                    />
                </picture>
                {caption && <figcaption>{ caption }</figcaption>}
            </figure>
        </div>
    );
};

export default Image;
