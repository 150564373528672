const TimeSinceNow = (dateIso: string) => {
    const now = new Date();
    const prevDate = new Date(dateIso);

    const diffSeconds = Math.ceil((now.getTime() - prevDate.getTime()) / 1000);

    if (Number.isNaN(diffSeconds)) {
        return '';
    }

    const oneMinute = 60;
    const oneHour = oneMinute * 60;
    const oneDay = oneHour * 24;
    const oneWeek = oneDay * 7;
    const oneMonth = oneDay * 30;

    let interval = diffSeconds / oneMonth;
    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? 'months' : 'month'} ago`;
    }
    interval = diffSeconds / oneWeek;
    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? 'weeks' : 'week'} ago`;
    }
    interval = diffSeconds / oneDay;
    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? 'days' : 'day'} ago`;
    }
    interval = diffSeconds / oneHour;
    if (interval > 1) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? 'hours' : 'hour'} ago`;
    }
    interval = diffSeconds / oneMinute;
    if (interval > 0) {
        return `${Math.floor(interval)} ${Math.floor(interval) > 1 ? 'minutes' : 'minute'} ago`;
    }

    return 'Just now';
};

export { TimeSinceNow as default };
