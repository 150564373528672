import React, { FunctionComponent } from 'react';

interface PaginationButtonProps {
    text: React.ReactNode,
    type: string,
    isActive?: boolean,
    hideInMobile?: boolean,
    url?: string,
    ariaLabel?: string,
    clickHandle?: () => void
}

const PaginationButton: FunctionComponent<PaginationButtonProps> = props => {
    const {
        text,
        type,
        isActive,
        hideInMobile,
        url,
        ariaLabel,
        clickHandle
    } = props;

    const displayClass = `pagination-button${isActive ? ' active' : ''}${hideInMobile ? ' hide-mobile' : ''}`;

    const buttonClick = () => {
        if (clickHandle) {
            clickHandle();
        }
    };

    return (
        <li>
            {type === 'link' && (
                <a href={url} className={displayClass} aria-label={ariaLabel}>
                    {text}
                </a>
            )}
            {type === 'button' && (
                <button type="button" className={displayClass} onClick={buttonClick} aria-label={ariaLabel}>
                    {text}
                </button>
            )}
            {type === 'static' && (
                <div className={displayClass} role="presentation" aria-label={ariaLabel}>
                    {text}
                </div>
            )}
        </li>
    );
};

export default PaginationButton;
