import React, { FunctionComponent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import PaginationButton from '3-components/PaginationButton/PaginationButton';
import LuminaryCredit from '3-components/LuminaryCredit/LuminaryCredit';

interface PaginationProps {
    currentPage: number,
    totalPage: number,
    totalItems: number,
    pageItemStart: number,
    pageItemEnd: number,
    // itemsPerPage: number,
    paginationHandle: (newPage: number) => void // eslint-disable-line no-unused-vars
}

const Pagination: FunctionComponent<PaginationProps> = props => {
    const {
        currentPage,
        totalPage,
        totalItems,
        // itemsPerPage,
        pageItemStart,
        pageItemEnd,
        paginationHandle
    } = props;

    const prevClickHandler = () => {
        if (paginationHandle) {
            paginationHandle(Math.max(currentPage - 1, 1));
        }
    };

    const pageClickHandler = (page: number) => {
        if (paginationHandle) {
            paginationHandle(page);
        }
    };

    const nextClickHandler = () => {
        if (paginationHandle) {
            paginationHandle(Math.min(currentPage + 1, totalPage));
        }
    };

    const paginationPadding = 1;
    const maxPage = (paginationPadding * 2) + 1;
    let startPage = 0;
    let endPage = 0;
    if (totalPage <= maxPage) {
        startPage = 1;
        endPage = totalPage;
    } else if (currentPage <= paginationPadding) {
        // Current page near the start
        startPage = 1;
        endPage = maxPage;
    } else if (currentPage + paginationPadding >= totalPage) {
        // Current page near the end
        startPage = totalPage - maxPage + 1;
        endPage = totalPage;
    } else {
        // Current page somewhere in the middle
        startPage = currentPage - paginationPadding;
        endPage = currentPage + paginationPadding;
    }

    const renderButtons = () => {
        const paginationItems = [];

        if (currentPage > 1) {
            // render prev button if current page is not the first
            paginationItems.push({
                type: 'button',
                text: (<>&lt;</>),
                ariaLabel: 'Previous page',
                clickHandle: prevClickHandler
            });
        }

        if (startPage > 1) {
            // render ... if the page 1 button not rendered by current + pagination
            paginationItems.push({
                type: 'static',
                text: (<>&hellip;</>)
            });
        }

        for (let i = startPage; i <= endPage; i += 1) {
            // render current page with pagination padding
            paginationItems.push({
                type: i === currentPage ? 'static' : 'button',
                text: `${i}`,
                isActive: i === currentPage,
                ariaLabel: `Page ${i}`,
                clickHandle: () => { pageClickHandler(i); }
            });
        }

        if (endPage < totalPage) {
            // render ... if the last page button not rendered by current + pagination
            paginationItems.push({
                type: 'static',
                text: (<>&hellip;</>)
            });
        }

        if (currentPage < totalPage) {
            // render next if current is not the last one
            paginationItems.push({
                type: 'button',
                text: (<>&gt;</>),
                ariaLabel: 'Next page',
                clickHandle: nextClickHandler
            });
        }

        return (
            <>
                {paginationItems.map(buttonData => (<PaginationButton key={uuidv4()} {...buttonData} />))}
            </>
        );
    };

    return (
        <div className="pagination">
            <div className="pagination-info">Displaying {pageItemStart}-{pageItemEnd} of {totalItems}</div>
            <ul className="pagination-buttons-container">
                {renderButtons()}
            </ul>
            <LuminaryCredit additionaClasses="mobile-hide" />
        </div>
    );
};

export default Pagination;
