import React from 'react';
import { SearchState } from 'react-instantsearch-core';

const SearchResult = ({ hit }: SearchState) => (
    // Example Custom Hit. This will usually be a Card component.
    <div className="eg-search-result">
        <h4 className="hit-title">{hit.name}</h4>
        <p className="hit-description">{hit.shortDescription}</p>
    </div>
);

export default SearchResult;
