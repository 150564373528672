import React from 'react';
import ReactDOM from 'react-dom';
import algoliasearch from 'algoliasearch/lite';
import { InstantSearch, Hits, Configure } from 'react-instantsearch-dom';

import AlgoliaPagination from '3-components/AlgoliaPagination/AlgoliaPagination';
import AlgoliaSearchInput from '3-components/AlgoliaSearchInput/AlgoliaSearchInput';
import SearchResult from './widgets/SearchResult';
import NoResults from './widgets/NoResults';

const APP_CLASS = 'algolia-search-app';

// NOTE: This is the demo Algolia searchClient. Update to your projects ID
const searchClient = algoliasearch(
    'latency',
    '6be0576ff61c053d5f9a3225e2a90f76'
);

const AlgoliaSearchApp = () => (
    <InstantSearch
        indexName="bestbuy"
        searchClient={searchClient}
    >
        <Configure
            hitsPerPage={9}
        />

        <div className="algolia-search-searchbar">
            <AlgoliaSearchInput />
        </div>

        <div className="algolia-search-results">
            <Hits hitComponent={SearchResult} />

            <NoResults />

            <AlgoliaPagination />
        </div>

    </InstantSearch>
);

const el = document.getElementById(`${APP_CLASS}`);

if (el) {
    ReactDOM.render(
        <AlgoliaSearchApp />,
        el
    );
}
