import React, { FunctionComponent } from 'react';
import Icon from '3-components/Icon/Icon';
import { ClickOrPressEvent } from 'types/mouseEvents';

interface ButtonProps {
    href?: string,
    btnText?: string,
    target?: string,
    rel?: string,
    btnSkin?: '1' | '2' | '3',
    btnSize?: 'sm' | 'md' | 'lg',
    isDisabled?: boolean,
    btnCustomClass?: string,
    onlyIcon?: boolean,
    onclick?: (e?: ClickOrPressEvent) => void, // eslint-disable-line no-unused-vars
    prefixIcon?: string,
    suffixIcon?: string,
    btnType?: 'button' | 'submit'
}

const Button: FunctionComponent<ButtonProps> = props => {
    const {
        btnText,
        href,
        target,
        rel,
        btnSkin = '1',
        btnSize = 'md',
        isDisabled,
        btnCustomClass = '',
        onlyIcon,
        onclick,
        prefixIcon,
        suffixIcon,
        btnType
    } = props;

    // "{{#if btnSkin}} btn-skin-{{btnSkin}}{{else}} btn-skin-1{{/if}}{{#if btnSize}} btn-size-{{btnSize}}{{else}} btn-size-md{{/if}}{{#if isDisabled}} btn-disabled{{/if}}{{#if btnCustomClass}} {{btnCustomClass}}{{/if}}{{#if onlyIcon}} btn-only-icon{{/if}}"
    const builtButtonClasses = `btn-skin-${btnSkin} btn-size-${btnSize}${isDisabled ? ' btn-disable' : ''}${btnCustomClass ? ` ${btnCustomClass}` : ''}${onlyIcon ? ' btn-only-icon' : ''}`;

    const clickHandle = (e?: ClickOrPressEvent) => {
        if (onclick) {
            onclick(e);
        }
    };

    return (
        <>
            {href && (
                <a
                    href={href}
                    target={target}
                    className={builtButtonClasses}
                    onClick={clickHandle}
                    rel={rel}
                >
                    {prefixIcon && (
                        <Icon icon={prefixIcon} />
                    )}
                    {btnText && (<span className="btn-text">{btnText}</span>)}
                    {suffixIcon && (
                        <Icon icon={suffixIcon} />
                    )}
                </a>
            )}
            {!href && (
                <button
                    type={btnType === 'submit' ? 'submit' : 'button'}
                    className={builtButtonClasses}
                    disabled={isDisabled}
                    onClick={clickHandle}
                >
                    {prefixIcon && (
                        <Icon icon={prefixIcon} />
                    )}
                    {btnText && (<span className="btn-text">{btnText}</span>)}
                    {suffixIcon && (
                        <Icon icon={suffixIcon} />
                    )}
                </button>
            )}
        </>
    );
};

export default Button;
