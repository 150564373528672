import fetch from 'cross-fetch';
import { DonationItem } from '../../@types/donationItem';

const API_GET_STARS_DONATION = 'api/GetStars';
const API_GET_ONE_STAR = 'api/GetStar';

export const DEFAULT_RESPONSE = {
    totalDonationAmount: 0,
    donations: {
        items: [] as DonationItem[],
        pageNumber: 0,
        pageSize: 10,
        totalPages: 0,
        totalItems: 0
    }
};
const starsListCache: Record<string, typeof DEFAULT_RESPONSE | undefined> = {};
const mainUrl = () => {
    const { hostname } = window.location;
    if (hostname === 'localhost'
        || hostname.includes('.luminary.space')) {
        return 'https://func-api-prod-003.azurewebsites.net/';
    }
    return 'https://mystar.givetocarols.com/';
};

interface getDonationListParams {
    pageNumber?: number;
    pageSize?: number;
}

async function getStarsList(params: getDonationListParams, abortController?: AbortController): Promise<
    typeof DEFAULT_RESPONSE
> {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' } as Record<string, string>,
        signal: abortController?.signal
    };

    const arrayParams: string[] = [];
    Object.keys(params).forEach(key => {
        arrayParams.push(`${key}=${params[key]}`);
    });
    const stringParams = arrayParams.join('&');
    const endpointPath = API_GET_STARS_DONATION;

    const requestUrl = `${mainUrl()}${endpointPath}?${stringParams}`;
    const cachedResponse = starsListCache[requestUrl];

    if (cachedResponse) return cachedResponse;

    const response = await fetch(requestUrl, requestOptions);

    if (response.ok) {
        try {
            const data = await response.json();
            starsListCache[requestUrl] = data;
            return data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }

    return DEFAULT_RESPONSE;
}

async function getStar(starId: string, abortController?: AbortController) {
    const requestOptions = {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' } as Record<string, string>,
        signal: abortController?.signal
    };

    const endpointPath = API_GET_ONE_STAR;

    const requestUrl = `${mainUrl()}${endpointPath}?mystar=${starId}`;

    const response = await fetch(requestUrl, requestOptions);

    if (response.ok) {
        try {
            const data = await response.json();
            return data;
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error(error);
        }
    }

    return false;
}

export { getStarsList, getStar };
