import React from 'react';
import { connectStateResults, ClearRefinements } from 'react-instantsearch-dom';
import { SearchState } from 'react-instantsearch-core';

const NoResults = ({ searchResults }: SearchState) => {
    if (!searchResults || searchResults.nbHits > 0) {
        return null;
    }

    return (
        <div className="hits-empty-state">
            <p className="hits-empty-state-title">No results found</p>
            <p className="hits-empty-state-description">Please try another search query.</p>

            <ClearRefinements
                clearsQuery={true}
                translations={{ reset: 'Reset search' }}
            />
        </div>
    );
};

export default connectStateResults(NoResults);
