const formatToAUCurrency = (amount: number) => new Intl.NumberFormat('en-AU', {
    style: 'currency',
    currency: 'AUD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
}).format(amount);

const formatHugeAmountToAUCurrency = (amount: number) => {
    let smallerAmount = amount / 1000000000;
    let amountText = 'billion';

    if (smallerAmount > 1000) {
        smallerAmount = Math.floor(smallerAmount / 10) / 100;
        amountText = 'trillion';
    }

    if (smallerAmount > 1000) {
        smallerAmount = Math.floor(smallerAmount / 10) / 100;
        amountText = 'quadrillion';
    }

    const currencyHeadAmount = new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    }).format(smallerAmount);

    return `Over ${currencyHeadAmount} ${amountText}`;
};

const formatToAUInt = (amount: number) => new Intl.NumberFormat('en-AU', {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
}).format(amount);

export { formatToAUCurrency, formatHugeAmountToAUCurrency, formatToAUInt };
