import React from 'react';
import Icon from '3-components/Icon/Icon';
import ReactModal from 'react-modal';
import { ClickOrPressEvent } from 'types/mouseEvents';

if (typeof window !== 'undefined') {
    ReactModal.setAppElement('body');
}

export interface ModalProps {
    onCloseClick?: (event: ClickOrPressEvent) => void; // eslint-disable-line no-unused-vars
    isOpen: boolean;
    ariaLabel: string;
    modalTitle: string;
    additionalClasses?: string;
    children: React.ReactNode;
    closeButtonPrefixText?: string;
}

const Modal = ({ isOpen, onCloseClick, children, ariaLabel, modalTitle, additionalClasses, closeButtonPrefixText }: ModalProps) => (
    <ReactModal
        isOpen={isOpen}
        onRequestClose={onCloseClick}
        contentLabel={ariaLabel}
        overlayClassName="modal-overlay"
        ariaHideApp={false}
        className={`modal-container ${additionalClasses}`}
    >
        <div className="modal-header">
            <h2>{modalTitle}</h2>
            <button className="modal-close" aria-label="Close modal" type="button" onClick={onCloseClick}>
                {closeButtonPrefixText && (
                    <span className="story-modal-close-text">{closeButtonPrefixText}</span>
                )}
                <Icon icon="remove" />
            </button>
        </div>
        <div className="modal-content">
            {children}
        </div>

    </ReactModal>
);

export default Modal;
