import Bouncer from 'formbouncerjs';

const initFormValidation = () => {
    // eslint-disable-next-line no-new
    new Bouncer('form.bouncer-validate', { errorClass: 'validation-message' });

    // Listen for errors on selects, to apply styling to parent.
    document.addEventListener('bouncerShowError', event => {
        let field: ParentNode | null = null;

        if (!event.target || !(event.target instanceof HTMLInputElement)) return;

        if (event.target && event.target.type === 'checkbox') {
            field = event.target.parentNode;
        } else if (event.target) {
            field = event.target.parentNode ? event.target.parentNode.parentNode : null;
        }

        // Add a class to the selects parent to colour the dropdown arrow red.
        if (field instanceof HTMLElement) {
            field.classList.remove('validation-success');
            field.classList.add('validation-error');
        }
    }, false);

    document.addEventListener('bouncerRemoveError', event => {
        let field: ParentNode | null = null;

        if (!event.target || !(event.target instanceof HTMLInputElement)) return;

        if (event.target.type === 'checkbox') {
            field = event.target.parentNode;
        } else {
            field = event.target.parentNode ? event.target.parentNode.parentNode : null;
        }

        // Remove the class from the selects parent that colours the dropdown arrow.
        if (field instanceof HTMLElement) {
            field.classList.remove('validation-error');
            field.classList.add('validation-success');
        }
    }, false);
};

document.addEventListener('DOMContentLoaded', () => {
    initFormValidation();
});
